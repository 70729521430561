import { AccessTokenContext } from '../../../utils/accessTokenProvider'
import { FormChartItem } from '../types'
import { Secret } from './fields/Secret'
import styles from './formChart.module.styl'
import { WidgetFormData } from './types'
import { Button, Spinner, Banner } from '@nike/eds'
import { Fragment, useContext, useState } from 'react'
import { useForm, SubmitHandler, Control } from 'react-hook-form'

interface FormChartProps {
  data?: FormChartItem[]
  dataSourceURL: string
  getRefreshedWidgetData?: () => Promise<void>
}

const renderField = (item: FormChartItem, control: Control<WidgetFormData>) => {
  switch (item.type) {
    case 'banner':
      return (
        <Banner type={item.status || 'info'}>
          {item.text.map((text) => (
            <p key={text}>{text}</p>
          ))}
        </Banner>
      )

    case 'secret':
      return <Secret item={item} control={control} />
  }
}

export const FormChart = ({ data, dataSourceURL, getRefreshedWidgetData }: FormChartProps) => {
  const { control, handleSubmit } = useForm<WidgetFormData>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const accessToken = useContext(AccessTokenContext)

  const onSubmit: SubmitHandler<WidgetFormData> = async (data: WidgetFormData) => {
    setIsSubmitting(true)

    try {
      await fetch(dataSourceURL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      })
    } catch (error) {
      console.error('[API error]', error)
    } finally {
      getRefreshedWidgetData?.()
      setIsSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formChart}>
      {data?.map((field: FormChartItem, key) => (
        <Fragment key={`${dataSourceURL}-${key}`}>{renderField(field, control)}</Fragment>
      ))}
      <div className={styles.actions}>
        <Button
          type='submit'
          disabled={isSubmitting}
          afterSlot={isSubmitting ? <Spinner /> : undefined}
        >
          Save
        </Button>
      </div>
    </form>
  )
}
